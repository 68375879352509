
import { defineComponent } from "vue";
import { homeOutline, informationCircleOutline, personOutline, exitOutline } from "ionicons/icons";
import { 
    IonFooter,
    IonIcon, 
    IonRow,
    IonCol ,
    IonLabel
} from "@ionic/vue";

export default defineComponent({
	name: "Footer",
    components: {
        IonFooter,
        IonIcon, 
        IonRow,
        IonCol ,
        IonLabel
    },
    setup() {
		return {
			icons: { 
				homeOutline,
				informationCircleOutline,
				personOutline,
        exitOutline
			},
		};
	},
});
