<template>
    <ion-item  @click="onClickItem" class="ion-no-padding d-flex ion-align-items-center">
        
        <img slot="start" class="question-th"  onerror="this.src='/assets/icon/logo-evote.png'" :src="url" :alt="title">
        <ion-label class="pt-5 mr-50" style="white-space: normal">
            <h2>
              {{ title }}
            </h2>
            <h3 class="mb-10">
              <ion-text class="color-gray">
                {{ subtitle }}
              </ion-text>
            </h3>
          <slot slot="end"></slot>
        </ion-label>
        <slot name="status"></slot>
    </ion-item>
</template>
<script lang="ts">
import { defineComponent, toRefs } from 'vue';
import {
	IonLabel,
    IonItem
} from "@ionic/vue";
 
const listItemImageProps = {
    id: {
        type: String,
        required: true
    },
    title: {
        type: String,
        required: true,
        default: "",
    },
    subtitle: {
        type: String,
        required: true,
        default: ""
    },
    url: {
        type: String,
        required: true,
        default: "",
    },
    status: {
        type: String,
        required: true,
        default: ""
    }
}
export default defineComponent({
    components: {
        IonLabel,
        IonItem
    },
    emits: ["onClickItem"],
    props: listItemImageProps,
    setup(props, {emit}) {
        const {
            title, 
            url,
            subtitle,
            id,
            status
        } = toRefs(props)

        const onClickItem = () => {
           emit("onClickItem", {url: url.value, title: title.value})
        }

        return {
            onClickItem,
            title, 
            url,
            subtitle,
            id,
            status
        }
    },
})
</script>
<style scoped>
.question-th{max-height: 90px;max-width: 90px;padding: 5px; margin-right: 10px;border-radius: 15px;}
</style>
