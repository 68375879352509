
import {
	IonContent,
	IonPage,
	IonList
} from "@ionic/vue";
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import Footer from "@/components/layout/Footer.vue";
import Header from '@/components/layout/Header.vue'
import { RootStore } from "@/store";
import { useRouter } from "vue-router";
import { Ballot } from "@/models/types";
import ListItemImage from '@/components/ListItemImage.vue'
import NotFound from '@/components/NotFound.vue'
import { getMomentDateFromInt } from "@/utils/utils";
import moment from "moment";
import { checkmarkCircle } from "ionicons/icons";

export default defineComponent({
	name: "dashboard",
	components: {
		IonContent,
		IonPage,
		IonList,
		Footer,
		Header,
		ListItemImage,
		NotFound
	},
	setup() {
		const store = useStore<RootStore>()
		const router = useRouter()
		const userInformations = JSON.parse(
			localStorage.getItem("userInformations") || "{}"
		);
		// Set User informations
		store.dispatch("setUserInformations", userInformations);
		// Reset current ballot if we arrive from intro
		store.dispatch("setCurrentBallot", {});
		// Get ballots list
		store.dispatch("getBallots");
		// Intialize the application
		store.dispatch("setInitialized")
		const ballots = computed<Ballot[]>(() => store.getters.ballots)

		const ballotIntro = (index: number) => {
			store.dispatch("setCurrentBallot", ballots.value[index]);
			router.push("vote-intro");
		}

		const ballotReport = (index: number) => {
			store.dispatch("setCurrentBallot", ballots.value[index]);
			router.replace("vote-report");
		}

		const ballotHasResults = (ballot: any) => {
			return Object.prototype.hasOwnProperty.call(ballot, "results");
		}

		const getBallotStatus = (ballot: Ballot): 'Chiuso' | 'Terminato' | 'In corso' => {
			const dateStart = getMomentDateFromInt(ballot.start)	
			const dateEnd = getMomentDateFromInt(ballot.end)	

			//CHIUSO
			if (moment().isBefore(dateStart)){
				return "Chiuso"
			} 
			//TERMINATO
			else if(moment().isAfter(dateEnd) || ballotHasResults(ballot)){
				return "Terminato"
			} 
			//IN CORSO
			else {
				return "In corso"
			}
		}
		const getUserBallotStatus = (ballot: Ballot): '' | 'Risultati' | 'Vota' | 'Già votato' => {
			const dateStart = getMomentDateFromInt(ballot.start)	
			const dateEnd = getMomentDateFromInt(ballot.end)	

			//CHIUSO
			if (moment().isBefore(dateStart)){
				return ""
			} 
			//TERMINATO
			if(ballotHasResults(ballot)){
				return "Risultati"
			}
			if (moment().isAfter(dateEnd) && !ballot.isVoted){
				return ""
			}
			return !ballot.isVoted ? "Vota" : "Già votato"
			
		}

		const getBallotStatusClass = (ballot: Ballot) => {
			const status = getBallotStatus(ballot)
			switch(status){
				case "Chiuso":
					return "cr-red"
				case "Terminato":
					return "cr-blue"
				case "In corso":
					return "cr-green"
			}
		}

		const onClickItemCallback = (ballot: Ballot, index: number) => {
			if (!['Risultati', 'Vota'].includes(getUserBallotStatus(ballot)) || ["Chiuso"].includes(getBallotStatus(ballot))){
				return
			}

			if(ballotHasResults(ballot)){
				//TODO: RIMUOVERE!!. fatto per non far vedere i risultati di pa social smatphone d'oro
				//return ballotReport(index)
			} else if (ballot.isVoted){
				return
			} else {
				return ballotIntro(index)
			}
		}
		
		return {
      icons: {checkmarkCircle},
			ballotIntro,
			ballotReport,
			ballotHasResults,
			ballots,
			onClickItemCallback,
			getBallotStatus,
			getUserBallotStatus,
			getBallotStatusClass
		};
	},
});
