<template>
    <!-- head vote custom -->
    <div class="head custom">
        <ion-toolbar class="transparent">
            <a v-if="anonymous" @click="onAnonymousClick">
                <ion-badge color="dark" slot="start">
                    <ion-icon
                        :icon="icons.informationCircleOutline"
                    />
                    Voto anonimo
                </ion-badge>
            </a>
        </ion-toolbar>

        <div v-if="displayLine" class="line-sx"></div>

        <div class="logo-content-centred ion-margin-start">
            <div
                class="logo-content2"
                :style="`background-image: url(${logoUrl}), url(/assets/logo-customer.png)`"
            ></div>
        </div>

        <div v-if="displayLine" class="line-dx"></div>
    </div>
    <!-- end header -->
</template>
<script lang="ts">
import { defineComponent, toRefs } from 'vue'
import {
	IonIcon,
	IonBadge,
	IonToolbar
} from "@ionic/vue"
import { informationCircleOutline } from "ionicons/icons";
const pageCustomHeaderProps = {
    anonymous:{
        type: Boolean,
        required: true,
    },
    logoUrl: {
        type: String,
        default: ""
    },
    displayLine: {
        type: Boolean,
        default: false
    }
}
export default defineComponent({
    components: {
        IonIcon,
        IonBadge,
        IonToolbar
    },
    props: pageCustomHeaderProps,
    emits: ["onAnonymousClick"],
    setup(props, {emit}) {
        const { anonymous, logoUrl, displayLine } = toRefs(props)
        
        const onAnonymousClick = () => {
            emit("onAnonymousClick")
        }
        return {
            anonymous,
            logoUrl,
            displayLine,
            onAnonymousClick,
            icons: {
                informationCircleOutline
            }
        }
    },
})
</script>
<style scoped>
ion-toolbar {
	--background: transparent;
	--ion-color-base: transparent !important;
}   
</style>