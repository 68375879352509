
import { IonContent, IonPage } from "@ionic/vue";
import { defineComponent } from "vue";
import Footer from "@/components/layout/Footer.vue";

export default defineComponent({
	name: "guide",
	components: {
		IonContent,
		IonPage,
		Footer
	}
});
