
import {
	IonContent,
	IonPage,
	IonFooter,
	IonButtons,
	IonButton,
	IonIcon,
	IonItem,
	IonToolbar,
	IonModal
} from "@ionic/vue";
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { closeCircle, informationCircleOutline, ellipse, ellipseOutline, eyeOff, shieldCheckmark, informationCircle } from "ionicons/icons";
import Modal from "../components/modals/index.vue";
import ProgressBarComponent from "@/components/layout/ProgressBarComponent.vue";
import useModal from "@/composables/ModalApi";

export default defineComponent({
	name: "VoteIntro",
	components: {
		IonContent,
		IonPage,
		IonFooter,
		IonButtons,
		IonButton,
		IonIcon,
		IonItem,
		IonToolbar,
		ProgressBarComponent,
		IonModal,
		Modal
	},
	setup() {
		const store = useStore()
		const currentBallot = computed(() => store.getters.currentBallot)
		const { scopedModalInfo, openModal, handleModalClosed } = useModal('scoped')

		return {
			icons: { closeCircle, informationCircleOutline, ellipse, ellipseOutline, eyeOff, shieldCheckmark, informationCircle },
			currentBallot,
			openModal,
			modalInfo: scopedModalInfo,
			handleModalClosed
		}
	},
});
