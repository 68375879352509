<template>
	<ion-page class="ion-page page-login">
		<Header/>
		<ion-row>
			<ion-col class="ion-no-padding">
				<div>
          <h2 class="ion-text-center">
            <ion-text color="primary">
              <b>Come si vota?</b>
            </ion-text>
          </h2>
				</div>
			</ion-col>
		</ion-row>
		<div class="fill__container">
			<ion-row class="w-100">
				<ion-col class="pl-0" size="2">
					<img onerror="this.src='/assets/icon/logo.png'" src="/assets/img/login_4rya.png" />
				</ion-col>
				<ion-col size="10">
					<h3 class="text-4rya mb-5"><b>Accedi in 4RYA</b></h3>
					<ion-text color="dark">
						<p>Entra in 4RYA premendo<br/> sul pulsante "Accedi"</p>
					</ion-text>
				</ion-col>
			</ion-row>
			<ion-row class="w-100">
				<ion-col class="ion-text-right" size="10">
					<h3 class="text-primary mb-5"><b>Crea una password</b></h3>
					<ion-text  color="dark">
						<p>Alla fine della registrazione<br/>crea e conserva la tua password </p>
					</ion-text>
				</ion-col>
				<ion-col class="pr-0 d-flex justify-content-end" size="2">
					<img height="110" onerror="this.src='/assets/icon/logo.png'" src="/assets/img/login_2.png" />
				</ion-col>
			</ion-row>
			<ion-row class="w-100">
				<ion-col class="pl-0" size="2">
					<img onerror="this.src='/assets/icon/logo.png'" src="/assets/img/login_3.png" />
				</ion-col>
				<ion-col size="10">
					<h3 class="text-success-ligth mb-5"><b>Vota!</b></h3>
					<ion-text color="dark">
						<p>Usa la password<br/>per votare...<br/>e non perderla! :P</p>
					</ion-text>
				</ion-col>
			</ion-row>
			<ion-row class="w-100">
				<AuthInComponent
					style="margin: 0 20px 20px 20px;width: 100%;"
					ref="authInComponent"
					:env="env"
					:onAuthInSuccess="setLoggedUser"
					:lang="'it-IT'"
					:apiendpoint="authinApiendpoint"
					:apikey="authinApikey"
					:application="'vot8'"
					@onAuthInReady="authReady"
					:visible="true"
				>
					<template v-slot:default>
						<p class="mb-0 text-center" slot="anonymous">
							Premi su accedi e procedi alla votazione
							<!-- <ion-row>
								<ion-col class="d-flex align-items-center" size="3">
									<img onerror="this.src='/assets/icon/logo.png'" src="/assets/img/fill_smile.png" />
								</ion-col>
								<ion-col size="9">
									<h2 class="text-authin"><b>Cosa Aspetti?</b></h2>
									<ion-text color="dark">
										<p>Premi su accedi e procedi alla votazione</p>
									</ion-text>
								</ion-col>
							</ion-row> -->
						</p>
						<p slot="logged" class="no-margin-bottom">Autenticati per procedere alle votazioni.</p>
						<p slot="unauthorized" class="no-margin-bottom">Autenticati per procedere alle votazioni.</p>
					</template>
				</AuthInComponent>
			</ion-row>
		</div>
	</ion-page>
</template>

<script lang="ts">
import {
	IonPage,
	IonRow,
	IonCol,
	IonText
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import * as allIcons from "ionicons/icons";
import { useStore } from "vuex"
import { useRouter } from "vue-router";
import { Account, binConversions, ECDSAKeyPair } from '@affidaty/t2-lib'
import AuthInComponent from '@/components/AuthInComponent.vue'
import Header from '@/components/layout/Header.vue'

export default defineComponent({
	name: "Home",
	components: {
		IonText,
		IonPage,
		IonRow,
		IonCol,
		Header,
		AuthInComponent
	},
	setup() {
		const store = useStore()
		const authInComponent = ref()
		const router = useRouter()

		const setLoggedUser = () => {
			const user = JSON.parse(localStorage.getItem("userInformations") || "");
			const account = new Account();
			const keyPair = new ECDSAKeyPair();
			keyPair.publicKey.setSPKI(binConversions.base58ToBuffer(user.publicKey)).then(() => {
				return account.setKeyPair(keyPair)
			}).then(() => {
				store.dispatch('setAccount', account);
				store.dispatch('setUserInformations', user);
				router.replace({name: "dashboard"});
			})
		}

		const authReady = () => {
			authInComponent.value.getUserInfo().then((infos: any) => {
				if(infos.logged) {
					setLoggedUser()
				}
			})
		}



		return {
			icons: allIcons,
			showLogin: false,
			setLoggedUser,
			env: process.env.VUE_APP_ENVIRONMENT,
			authinApplication: process.env.VUE_APP_AUTHIN_APPLICATION,
			authinApiendpoint: process.env.VUE_APP_AUTHIN_APIENDPOINT,
			authinApikey: process.env.VUE_APP_AUTHIN_APIKEY,
			authReady,
			authInComponent
		};
	},
});
</script>

<style scoped>
.page-login{background-color: var(--ion-color-secondary-contrast);}
.logo{margin: 15px auto;}
.fill__container{
	height: 100%;
	display: flex;
    align-content: stretch;
    flex-wrap: wrap;
	overflow-y: auto;
	background: url(/assets/img/bg_fill.png) no-repeat;
	background-size: 95%;
    background-position: center;
    background-position-y: 65px;
}
.text-4rya{color: var(--ion-color-danger-tint)}
.text-primary{color: var(--ion-color-primary-shade)}
.text-success-ligth{color: var(--ion-color-success-tint)}
.text-authin{color: var(--ion-color-tertiary-shade)}
</style>