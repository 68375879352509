
import {
  IonText
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
	components: {
    IonText
	},
    setup() {
        return {}
    }
});
