<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <PageCustomHeader />

      <ion-grid>
        <ion-row>
          <ion-col class="d-flex ion-justify-content-center">
            <ion-avatar class="custom avatar-badge">
              <ion-icon
                class="avatar-badge__icon"
                :icon="icons.checkmarkCircle"
              ></ion-icon>
              <img src="assets/img/avatar.png" />
            </ion-avatar>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="mb-20">
            <div class="title-section ion-padding">
              <h2 class="ion-text-center page-title">
                GRAZIE PER AVER VOTATO!
              </h2>
            </div>
          </ion-col>
        </ion-row>
        <ion-row class="justify-content-center">
          <ion-col size="10" class="ion-text-center">
            <ion-button
              expand="full"
              shape="round"
              class="ionic-text-center mb-20"
              color="primary"
              href="/dashboard"
            >
              <ion-icon :icon="icons.homeOutline" slot="end"></ion-icon>
              Vedi le tue votazioni
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <Footer></Footer>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonPage,
  IonIcon,
  IonAvatar,
  IonCol,
  IonRow,
  IonButton,
  IonGrid,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { checkmarkCircle, homeOutline, fileTrayFullOutline } from "ionicons/icons";
import Footer from "@/components/layout/Footer.vue";
import PageCustomHeader from '@/components/PageCustomHeader.vue'

export default defineComponent({
  name: "vote-thanks",
  components: {
    IonContent,
    IonPage,
    IonIcon,
    IonAvatar,
    IonCol,
    IonRow,
    IonButton,
    IonGrid,
    Footer,
    PageCustomHeader
  },
  methods: {},
  setup() {
    return {
      icons: { checkmarkCircle, homeOutline, fileTrayFullOutline }
    };
  },
});
</script>

<style scoped>
.title-section .page-title,
.title-section {
  margin: 0px;
  padding: 0px;
}

.title-section {
  padding-bottom: 0px;
}
.logo-content-centred {
  margin-top: 30px;
}
</style>
