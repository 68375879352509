
import { defineComponent, toRefs } from 'vue'
import {
	IonIcon,
	IonBadge,
	IonToolbar
} from "@ionic/vue"
import { informationCircleOutline } from "ionicons/icons";
const pageCustomHeaderProps = {
    anonymous:{
        type: Boolean,
        required: true,
    },
    logoUrl: {
        type: String,
        default: ""
    },
    displayLine: {
        type: Boolean,
        default: false
    }
}
export default defineComponent({
    components: {
        IonIcon,
        IonBadge,
        IonToolbar
    },
    props: pageCustomHeaderProps,
    emits: ["onAnonymousClick"],
    setup(props, {emit}) {
        const { anonymous, logoUrl, displayLine } = toRefs(props)
        
        const onAnonymousClick = () => {
            emit("onAnonymousClick")
        }
        return {
            anonymous,
            logoUrl,
            displayLine,
            onAnonymousClick,
            icons: {
                informationCircleOutline
            }
        }
    },
})
