<template>
	<ion-page>
		<ion-content :fullscreen="true">
      <h2 class="ion-text-center mt-30">
        <ion-text color="primary">
          <b>Info</b>
        </ion-text>
      </h2>
			<section class="ion-margin login-section">
        <p class="mb-0"><b>Termini e condizioni d’uso modulo e-Vote</b></p>
        <p><a href="https://legal.affidaty.io/it/termini-e-condizioni-user-e-vote/" target="_blank">
          <ion-button class="ion-no-margin mt-5" size="small" shape="round">Link</ion-button>
        </a></p>
        <p class="mb-0"><b>Informativa sulla privacy di e-Vote</b></p>
        <p>
          <a color="primary" href="https://legal.affidaty.io/it/privacy-e-vote/" target="_blank">
            <ion-button class="ion-no-margin mt-5" size="small" shape="round">Link</ion-button>
          </a>
        </p>

      </section>
		</ion-content>
		<Footer></Footer>
	</ion-page>
</template>

<script lang="ts">
import { IonContent, IonPage } from "@ionic/vue";
import { defineComponent } from "vue";
import Footer from "@/components/layout/Footer.vue";

export default defineComponent({
	name: "guide",
	components: {
		IonContent,
		IonPage,
		Footer
	}
});
</script>

<style scoped>
</style>