import { TermsConditions, TransactionAccountData } from "../models/types";
import { Account, ECDSAKey, Transaction, Utils } from "@affidaty/t2-lib";
declare type TKeyType = 'public' | 'private';


export default class Tx {

    static createTx(account: TransactionAccountData, method: string, args: Record<string, any>, contract?: string): Promise<Transaction> {
        return new Promise((resolve) => {
            const tx = new Transaction();
            tx.accountId = account.accountId;
            tx.signerPublicKey = account.publicKey
            tx.genNonce();
            tx.networkName = process.env.VUE_APP_BC_NETWORK!;
            tx.smartContractHashHex = (contract) ? contract : "";
            tx.smartContractMethod = method;
            tx.smartContractMethodArgs = args;
            resolve(tx);
        });
    }

    // TODO: Prevedere la possibilita` di firmare un vettore di transazioni
    static signTx(tx: Transaction, authInRef: any, termsConditionsUrl: TermsConditions[] = []): PromiseLike<string> {
        return new Promise((resolve, reject) => {
            tx.toObject().then((objectTxToSign) => {
                //console.log(objectTxToSign);
                return authInRef.signTransaction(objectTxToSign, termsConditionsUrl)
            }).then((objectTx: any) => {
                return tx.fromObject(objectTx);
            }).then(() => {
                return tx.toBase58();
            }).then((base58Tx: string) => {
                resolve(base58Tx);
            }).catch((reject));
        })
    }

    static signMultipleTx(tx: Transaction[], authInRef: any, termsConditionsUrl: TermsConditions[] = []): PromiseLike<string[]> {
        return new Promise((resolve, reject) => {
            const objectTransaction = tx.map((singleTx) => {
                return singleTx.toObject();
            })

            Promise.all(objectTransaction).then((promisedTransactions) => {
                //console.log("PROMISED TX:", promisedTransactions )
                return authInRef.signTransaction(promisedTransactions, termsConditionsUrl);
            }).then((signedObjectTxArray) => {
                const base58Transaction: string[] = signedObjectTxArray.map((transaction: any) => {
                    const dummyTx = new Transaction()
                    return dummyTx.fromObject(transaction).then(() => {
                        return dummyTx.toBase58()
                    })
                })

                Promise.all(base58Transaction).then((base58Array: string[]) => resolve(base58Array)).catch(reject);
            }).catch(reject)
        })
    }

    static decodeTransaction(tx: string): PromiseLike<Record<string, any>> {
        return new Promise((resolve) => {
            const transaction = new Transaction();
            transaction.fromBase58(tx).then(() =>{
                return transaction.toObject();
            }).then((object) => {
                object.data.args = Utils.bytesToObject(object.data.args)
                resolve(object);
            })
        })
    }

    static getAccountIdFromPublicKey(publicKey: Uint8Array): PromiseLike<string> {
        return new Promise((resolve) => {
            const account = new Account();
            const pubKey = new ECDSAKey('public');
            pubKey.setSPKI(publicKey).then(() => {
                return account.setPublicKey(pubKey);
            }).then(() => {
                resolve(account.accountId);
            })    
        })        
    }

    /**
    * Import an exported key into ECDSKey object
    * 
    * @param type The exported key type, private or public
    * @param exportedKey Key buffer
    * 
    * @returns Promise<ECDSAKey>
    */
   static importKey(type: TKeyType, exportedKey: Uint8Array): Promise<ECDSAKey> {
       return new Promise((resolve, reject) => {
           const key = new ECDSAKey(type);
           if(type === 'public')
               key.setSPKI(exportedKey).then(() => {
                   resolve(key);
               }).catch(() => {
                   reject('error importing key')
               })
           else if(type === 'private')
               key.setPKCS8(exportedKey).then(() => {
                   resolve(key)
               }).catch(() => {
                   reject('error importing key')
               })
           else 
               reject('Invalid type')
       })
   }
}