<template>
	<ion-page>
		<Header/>
		<ion-content>
			<section
				class="ion-padding login-section ion-text-center h100 d-grid pt-0"
			>
				<div class="content">

					<h2 class="ion-text-center">
						<ion-text color="primary">
						<b>Lista votazioni</b>
						</ion-text>
					</h2>

					<!-- Votation list -->
					<ion-list class="votation-list" v-if="ballots && ballots.length">
						<template
							v-for="(ballot, index) in ballots"
							:key="ballot.owner.accountId"
						>
							<ListItemImage
								@onClickItem="onClickItemCallback(ballot, index)"
								:title="ballot.title.it"
								:subtitle="`${$filters.formatDate(ballot.start)} - ${$filters.formatDate(ballot.end)}`"
								:url="'https://cdn.affidaty.io/misc/smartphone-doro.jpg'"
								:id="ballot.owner.accountId"
							>
								<template v-slot:default>
									<ion-button v-if="['Risultati', 'Vota'].includes(getUserBallotStatus(ballot))" shape="round" color="primary">{{getUserBallotStatus(ballot)}}</ion-button>
									<ion-text v-if="getUserBallotStatus(ballot) === 'Già votato'" color="primary"><p class="d-flex ion-align-items-center"><ion-icon class="fs-30 mr-5" :icon="icons.checkmarkCircle"
                  ></ion-icon><b>{{getUserBallotStatus(ballot)}}</b></p></ion-text>
								</template>
								<template v-slot:status>
									<div :class="`cr cr-top cr-right ${getBallotStatusClass(ballot)}`">{{getBallotStatus(ballot)}}</div>
								</template>
							</ListItemImage>

						</template>
					</ion-list>
					<!-- end Votation list -->
					<NotFound v-else>
						<p>Nessuna votazione disponibile</p>
					</NotFound>
				</div>
			</section>
		</ion-content>
		<Footer/>
	</ion-page>
</template>

<script lang="ts">
import {
	IonContent,
	IonPage,
	IonList
} from "@ionic/vue";
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import Footer from "@/components/layout/Footer.vue";
import Header from '@/components/layout/Header.vue'
import { RootStore } from "@/store";
import { useRouter } from "vue-router";
import { Ballot } from "@/models/types";
import ListItemImage from '@/components/ListItemImage.vue'
import NotFound from '@/components/NotFound.vue'
import { getMomentDateFromInt } from "@/utils/utils";
import moment from "moment";
import { checkmarkCircle } from "ionicons/icons";

export default defineComponent({
	name: "dashboard",
	components: {
		IonContent,
		IonPage,
		IonList,
		Footer,
		Header,
		ListItemImage,
		NotFound
	},
	setup() {
		const store = useStore<RootStore>()
		const router = useRouter()
		const userInformations = JSON.parse(
			localStorage.getItem("userInformations") || "{}"
		);
		// Set User informations
		store.dispatch("setUserInformations", userInformations);
		// Reset current ballot if we arrive from intro
		store.dispatch("setCurrentBallot", {});
		// Get ballots list
		store.dispatch("getBallots");
		// Intialize the application
		store.dispatch("setInitialized")
		const ballots = computed<Ballot[]>(() => store.getters.ballots)

		const ballotIntro = (index: number) => {
			store.dispatch("setCurrentBallot", ballots.value[index]);
			router.push("vote-intro");
		}

		const ballotReport = (index: number) => {
			store.dispatch("setCurrentBallot", ballots.value[index]);
			router.replace("vote-report");
		}

		const ballotHasResults = (ballot: any) => {
			return Object.prototype.hasOwnProperty.call(ballot, "results");
		}

		const getBallotStatus = (ballot: Ballot): 'Chiuso' | 'Terminato' | 'In corso' => {
			const dateStart = getMomentDateFromInt(ballot.start)	
			const dateEnd = getMomentDateFromInt(ballot.end)	

			//CHIUSO
			if (moment().isBefore(dateStart)){
				return "Chiuso"
			} 
			//TERMINATO
			else if(moment().isAfter(dateEnd) || ballotHasResults(ballot)){
				return "Terminato"
			} 
			//IN CORSO
			else {
				return "In corso"
			}
		}
		const getUserBallotStatus = (ballot: Ballot): '' | 'Risultati' | 'Vota' | 'Già votato' => {
			const dateStart = getMomentDateFromInt(ballot.start)	
			const dateEnd = getMomentDateFromInt(ballot.end)	

			//CHIUSO
			if (moment().isBefore(dateStart)){
				return ""
			} 
			//TERMINATO
			if(ballotHasResults(ballot)){
				return "Risultati"
			}
			if (moment().isAfter(dateEnd) && !ballot.isVoted){
				return ""
			}
			return !ballot.isVoted ? "Vota" : "Già votato"
			
		}

		const getBallotStatusClass = (ballot: Ballot) => {
			const status = getBallotStatus(ballot)
			switch(status){
				case "Chiuso":
					return "cr-red"
				case "Terminato":
					return "cr-blue"
				case "In corso":
					return "cr-green"
			}
		}

		const onClickItemCallback = (ballot: Ballot, index: number) => {
			if (!['Risultati', 'Vota'].includes(getUserBallotStatus(ballot)) || ["Chiuso"].includes(getBallotStatus(ballot))){
				return
			}

			if(ballotHasResults(ballot)){
				//TODO: RIMUOVERE!!. fatto per non far vedere i risultati di pa social smatphone d'oro
				//return ballotReport(index)
			} else if (ballot.isVoted){
				return
			} else {
				return ballotIntro(index)
			}
		}
		
		return {
      icons: {checkmarkCircle},
			ballotIntro,
			ballotReport,
			ballotHasResults,
			ballots,
			onClickItemCallback,
			getBallotStatus,
			getUserBallotStatus,
			getBallotStatusClass
		};
	},
});
</script>

<style scoped>

</style>