<template>
  <div class="ion-padding-horizontal">
    <ion-text color="dark">
      <p>Dalle ore 9:00 del giorno 8 novembre 2024 e fino alle 18:00 del giorno 20 novembre 2024 , tutti potranno esprimere la propria preferenza tramite l'App di PA Social.</p>
    </ion-text>
  </div>
</template>


<script lang="ts">
import {
  IonText
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
	components: {
    IonText
	},
    setup() {
        return {}
    }
});
</script>

<style scoped>

</style>
