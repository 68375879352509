
import { defineComponent } from 'vue'
import {
	IonHeader,
} from "@ionic/vue";

export default defineComponent({
    components: {
        IonHeader
    }
})
