import { ApiResponse, Ballot, BallotResultLocal, BallotResultQuestion, BallotResultQuestionLocal, User } from '@/models/types';
import { Account, binConversions, ECDSAKey, Signable } from '@affidaty/t2-lib';
import { AxiosResponse } from 'axios';
import { createLogger, createStore } from 'vuex';
import http from "../services/http";


async function verifyBallot(ballot: Omit<Ballot, "icon" | "logo" | "hash" | "signature" | "isVoted" | "status" | "results" | "publicBallot">, signature: string, hash: string) {
    return new Promise((resolve, reject) => {
        const signable = new Signable()
        signable.fromObject({data: {
            title: ballot.title,
            description: ballot.description,
            start: ballot.start,
            end: ballot.end,
            anonymous: ballot.anonymous,
            rules: ballot.rules,
            questions: ballot.questions,
            'polling_stations': ballot['polling_stations'],
            applicationKey: ballot.applicationKey,
            owner: ballot.owner
        }, signature: new Uint8Array(binConversions.base58ToArrayBuffer(signature))}).then(() => {
            const pubKey = new ECDSAKey('public');
            pubKey.setRaw(binConversions.base58ToBuffer(ballot.owner!.publicKey)).then(() => {
                signable.verifySignature(pubKey).then((isVerified: boolean) => {
                    resolve({verified: isVerified, hash: hash})
                })
            })
        })
        .catch((err) => reject({error: err, hash}));
    })
}

function getQuestion(questionId: string, currentBallot: Ballot) {
    for(const question of currentBallot.questions) {
        if(question.id === questionId) return question.question
    }
}

function getOption(questionId: string, optionId: string, currentBallot: any) {
    for(const question of currentBallot.questions) {
        if(question.id === questionId) {
        for(const option of question.options) {
            if (option.id === optionId) return option.value
        }
        }
    }
}

export interface RootStore {
    account: Account;
    userInformations: User;
    authInRef: any;
    ballots: Ballot[];
    currentBallot: Ballot;
    currentVote: any;
    initialized: boolean;
    currentBallotResults: BallotResultLocal;
    //ui
    showVideoPlayer: {url: string; title: string};
}

export const store = createStore<RootStore>({
    state() {
        return {
            account: {} as Account,
            authInRef: undefined,
            ballots: [],
            currentBallot: {} as Ballot,
            currentVote: [],
            initialized: false,
            userInformations: {} as User,
            currentBallotResults: {} as BallotResultLocal,
            showVideoPlayer: {} as {url: string; title: string}
        }
    },
    // Asynchronous actions
    actions: {
        setInitialized({ commit }) {
            commit("setInitialized");
        },

        setUserInformations({ commit }, payload) {
            commit("setUserInformations", payload);
        },
        
        setAccount({ commit }, payload: Account) {
            commit("setAccount", payload);
        },

        setCurrentVote({ commit }, payload) {
            commit("setCurrentVote", payload);
        },

        setCurrentBallot({ commit }, payload) {
            commit("setCurrentBallot", payload);
        },

        getBallots({ state, commit }) {
            http.post('/vot8/getBallots', {
                accountId: state.account.accountId,
                applicationKey: process.env.VUE_APP_AUTHIN_APIKEY,
            }).then( async (response: AxiosResponse<ApiResponse<Ballot[]>>) => {

                const promises: Promise<any>[] = [];
                const ballotsToVerify: Ballot[] = response.data.data.map(ballot => ({
                    ...ballot,
                    publicBallot: (ballot as any).public
                }))
                const ballots: Ballot[] = []

                ballotsToVerify.forEach((b) => {
                    delete (b as any)["public"]
                    const {isVoted, hash, signature, status, results, publicBallot, logo, icon,  ...filteredBallot} = b
                    promises.push(verifyBallot({...filteredBallot}, signature, hash));
                })

                Promise.all(promises).then((verificationResults: any) => {
                    for(const result of verificationResults) {
                        if(result.verified) {
                            // If not verified, drop ballot
                            const ballot = ballotsToVerify.find(ball => ball.hash === result.hash)
                            ballot? ballots.push(ballot) : null
                        }
                    }

                    commit("setBallots", ballots)
                }).catch((err) => {
                    // TODO: Error importing some ballot keys
                    commit("setBallots", []);
                })
            })
        },

        getCurrentBallotResults({commit}, payload) {
            http.post('/vot8/getBallotResult', payload).then((response: AxiosResponse<ApiResponse<BallotResultQuestion[]>>) => {
                // Reorder answer votes descending
                response.data.data.forEach(question => {
                    question.result.sort((a, b) => {
                        if(a.votes > b.votes) return -1
                        if(a.votes < b.votes) return 1
                        return 0;
                    });
                })
                commit("setCurrentBallotResults", response.data.data)
            })
        },

        resetVotation({ commit }) {
            commit("resetVotation");
        },

        showVideoPlayer: ({commit}, payload: {url: string; title: string}) => {
            commit("showVideoPlayer", payload)
        }
    },

    // Get the state
    getters: {
        initialized: (state) => state.initialized,
        userInformations: (state) => state.userInformations,
        ballots: (state) => state.ballots,
        currentBallot: (state) => state.currentBallot,
        currentVote: (state) => state.currentVote,
        currentBallotResults: (state) => state.currentBallotResults,
        showVideoPlayer: (state) => state.showVideoPlayer
    },

    // Set the states
    mutations: {
        setInitialized(state) {
            state.initialized = true;
        },

        setUserInformations(state, payload: User) {
            state.userInformations = payload;
        },

        setAccount: (state, payload: Account) => {
            state.account = payload
        },

        setCurrentVote(state: any, payload) {
            const index = state.currentVote.findIndex((vote: any) => vote.id === payload.id);
            if (index === -1) {
                state.currentVote.push(payload);
            } else {
                state.currentVote[index] = payload;
            }
        },

        setCurrentBallot(state: any, payload) {
            state.currentBallot = payload;
        },

        setBallots(state: any, payload) {
            state.ballots = payload;
        },

        setCurrentBallotResults(state, payload: BallotResultQuestion[]) {
            const result: BallotResultQuestionLocal[] =  payload.map(question => {
                return {
                    ...question,
                    title: getQuestion(question.id, state.currentBallot) || "",
                    result: question.result.map(option => {
                        return {
                            ...option,
                            title: getOption(question.id, option.id, state.currentBallot) || ""
                        }
                    })
                }
            })

            state.currentBallotResults = {
                title: state.currentBallot.title.it,
                result
            }
        },

        resetVotation(state: any) {
            state.currentVote = [];
        },

        setAuthInRef: (state, payload) => {
            state.authInRef = payload
        },

        showVideoPlayer: (state, payload: {url: string; title: string}) => {
            state.showVideoPlayer =  payload
        },
        hideVideoPlayer: (state) => {
            state.showVideoPlayer =  {} as {url: string; title: string}
        }
    },
    plugins: process.env.NODE_ENV !== 'production'
      ? [createLogger()]
      : []
});
