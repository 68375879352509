<template>
    <ion-row :class="`videorow ${isSelected? 'active' : ''}`">
        <ion-col size="4" class="image-col cursor-pointer" @click="openVideo">
            <img class="question-th"  onerror="this.src='/assets/icon/logo-evote.png'" :src="getThumbnailFromYoutube(url)" :alt="title">
            <div class="backdrop-play-th"></div>
            <ion-icon class="play-th" :icon="icons.playCircleOutline"></ion-icon>
        </ion-col>
        <ion-col size="8" class="d-flex flex-column align-items-start text-col">
            <div class="mb-auto video-title"><ion-text color="dark"><b>{{title}}</b></ion-text></div>
            <div class="d-flex justify-content-end w-100 videorow__buttons">
                <button :class="`button-round button-medium video-button-selection ${!isSelected?'outline':''}`" @click="onVote" >
                    {{buttonLabel}}<ion-icon class="video-button-selection__icon" :icon="icons.checkmarkCircleOutline"></ion-icon> 
                </button>
            </div>
        </ion-col>
    </ion-row>
</template>
<script lang="ts">
import { computed, defineComponent, toRefs } from 'vue';
import { checkmarkCircleOutline, personOutline, playCircleOutline } from "ionicons/icons";
import {
    IonButton,
	IonLabel,
    IonIcon, 
    IonRow,
    IonCol,
    IonText
} from "@ionic/vue";
import {getThumbnailFromYoutube} from '../../utils/youTubeUtils'
 
const videoListItemProps = {
    id: {
        type: String,
        required: true
    },
    selected: {
        type: Object,
        required: true,
        default: false
    },
    title: {
        type: String,
        required: true,
        default: "",
    },
    url: {
        type: String,
        required: true,
        default: "",
    },
    buttonLabel: {
        type: String,
        required: true
    },
}
export default defineComponent({
    components: {
        IonCol,
        //IonButton,
        IonIcon, 
        //IonLabel,
        IonRow,
        IonText
    },
    emits: ["onVoteItem", "onOpenVideo"],
    props: videoListItemProps,
    setup(props, {emit}) {
        const {
            title, 
            url, 
            buttonLabel, 
            id,
            selected
        } = toRefs(props)



        const openVideo = () => {
           emit("onOpenVideo", {url: url.value, title: title.value})
        }

        const onVote = () => {
           emit("onVoteItem", id!.value)
        }

        return {
            getThumbnailFromYoutube,
            onVote,
            title, 
            url, 
            buttonLabel,
            openVideo,
            icons: { 
                personOutline,
                checkmarkCircleOutline,
                playCircleOutline
            },
            isSelected: computed(() => (selected.value as any) == id!.value)
        }
    },
})
</script>
<style scoped>

.image-col{padding:0px!important}
ion-grd{padding: 0px!important;}
.question-th{border-radius: 5px;height: 100px; width: 150px;}
.flex-column{flex-direction: column;}
.align-items-start{align-items: flex-start;}
.justify-content-end{justify-content: flex-end;}
.mb-auto{margin-bottom: auto;}
.w-100{width: 100%;}
.text-col{padding-top: 0px; padding-bottom:0px}
.video-title{margin-left: 10px;}
.videorow{border-bottom: 1px solid #f0f0f0; border-radius: 5px;padding: 10px;}
.videorow.active{background-color: rgba( var(--ion-color-primary-rgb), 0.2);}
.videorow.active .video-title{color: var(--ion-color-primary-contrast);}
.videorow.active .videorow__buttons ion-button{color: #f0f0f0;}
.videorow__buttons ion-button{font-size: 10px; margin: 0px; margin-right: -5px;}
.play-th,.backdrop-play-th{position: absolute;top: 0;bottom: 0;right: 0;left: 0;margin: auto;}
.play-th{ height: 32px;width: 32px;color: var(--ion-color-primary-contrast)}
.backdrop-play-th{height: 100%;width: 100%;background-color: rgba(0, 0, 0, .2);}
.video-button-selection{background-color: var(--ion-color-primary);color: var(--ion-color-primary-contrast); border-radius: 25px; padding: 8px 15px;display: flex;align-items: center; font-size: 10px; text-transform: uppercase;font-weight: 600;}
.video-button-selection.outline{ border: 2px solid var(--ion-color-primary); color:var(--ion-color-primary); background-color: var(--ion-color-primary-contrast);}
.video-button-selection__icon{margin-left: 5px; font-size: 14px;}
</style>
