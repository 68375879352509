
import { IonFabButton } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
	components: {
		IonFabButton,
	},
	name: "SlideMenuComponent",
	data() {
		return {
			content: "",
		};
	},
	props: {
		questions: {
			type: Object,
			required: true,
		},
		index: {
			type: Number,
			required: true,
		},
	},
	setup() {
		return {};
	},
});
