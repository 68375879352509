
import {
	IonContent,
	IonHeader,
	IonToolbar,
	IonIcon,
	IonText,
	modalController,
} from "@ionic/vue";
import { defineComponent, ref, toRefs } from "vue";
import { closeCircleOutline, informationCircle } from "ionicons/icons";
import AnonymousModal from "@/components/modals/AnonymousModal.vue";
import BallotInformationModal from "@/components/modals/BallotInformationModal.vue";
import QuestionModal from "@/components/modals/QuestionModal.vue";
import LanguageModal from "@/components/modals/LanguageModal.vue";
import PasswordModal from "@/components/modals/PasswordModal.vue";
import BallotTermsConditions from "@/components/modals/BallotTermsConditions.vue"
const modalProps = {
	type: {
		type: String,
		default: null,
		required: true,
	},
	title: {
		type: String,
	},
	data: {
		type: Object,
	},
	info: {
		type: String,
		default: ""
	}
}
export default defineComponent({
	components: {
		IonContent,
		IonHeader,
		IonToolbar,
		IonIcon,
		IonText,
		AnonymousModal,
		BallotInformationModal,
		QuestionModal,
		LanguageModal,
		PasswordModal,
		BallotTermsConditions
	},
	name: "Modal",
	data() {
		return {
			content: "",
		};
	},
	props: modalProps,
	methods: {
		handleDidDismiss(isCancelled: boolean) {
			modalController.dismiss({ type: this.type, event: "close" });
			this.$emit("modal-closed", { isCancelled });
		},

		handleDidSubmit(data: any) {
			modalController.dismiss({
				type: this.type,
				event: "submit",
				data: data,
			});
			this.$emit("modal-submit", { data });
		},
	},

	setup(props) {
		const { info } = toRefs(props)
		const showInfoModal = ref<boolean>(false)
		const showInfo = () => {
			showInfoModal.value = !showInfoModal.value 
		}
		return {
			info,
			showInfoModal,
			showInfo,
			icons: {closeCircleOutline, informationCircle},
		};
	},
	emits: ["modal-closed", "modal-submit"],
});
