
import { Ballot } from "@/models/types";
import { Subtle, Utils } from "@affidaty/t2-lib";
import {
/* 	IonRow, 
	IonCol,  */
	IonButton,
	IonText,
	IonThumbnail,
	IonLabel,
	IonList,
	IonCardContent,
	IonCard,
	IonItem
} from "@ionic/vue";
import { defineComponent, PropType } from "vue";

export default defineComponent({
	components: {
/* 		IonRow,
		IonCol, */
		IonButton,
		IonText,
		IonThumbnail,
		IonLabel,
		IonList,
		IonCardContent,
		IonCard,
		IonItem
	},
	name: "BallotInformationModal",
	data() {
		return {
			hashChecked: false,
			hashValid: false,
			hash: "",
		};
	},
	props: {
		ballot: {
			type: Object as PropType<Ballot>,
			required: true,
		},
	},

	mounted() {
		this.hash = this.ballot.hash;
	},

	methods: {
		checkHash() {
			// Clone ballot object 
			const {isVoted, hash, signature, status, results, publicBallot, logo, icon,  ...filteredBallot} = {...this.ballot};
      const data = {
				title: this.ballot.title,
				description: this.ballot.description,
				start: this.ballot.start,
				end: this.ballot.end,
				anonymous: this.ballot.anonymous,
				rules: this.ballot.rules,
				questions: this.ballot.questions,
				'polling_stations': this.ballot['polling_stations'],
				applicationKey: this.ballot.applicationKey,
				owner: this.ballot.owner
			}
			// hash the message
			Subtle.digest('SHA-256', Utils.objectToBytes(data)).then((hashBuffer: ArrayBuffer) => {
				// convert buffer to byte array
				const hashArray = Array.from(new Uint8Array(hashBuffer));            
				// convert bytes to hex string         
				const ballotHashString = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
				this.hashChecked = true;
				this.hashValid = hash === ballotHashString;	
			})         

			// ObjectTransporter.hash(ballot).then((ballotHash: any) => {
			// 	this.hashChecked = true;
			// 	this.hashValid = this.hash === ballotHash;
			// });
		},
	},
});
