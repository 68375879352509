<template ref="template">
	<!-- Multiple -->
	<template  v-if="isMultiple()">
		<ion-list mode="md">
			<template v-if="isMultipleRadioValue()">

				<!-- multiple radio -->
				<ion-radio-group @ionChange="handleChange">

					<template v-for="option in question.options" :key="option.id">
						
						<!-- html option -->
						<ion-item v-if="option.question === 'html'" lines="none">
							<ion-label>{{ option.value }}</ion-label>
							<ion-radio
								slot="start"
								:value="option.id"
								:model="answer.value"
							></ion-radio>
						</ion-item>

						<!-- video option -->
						<div v-else-if="option.question === 'video'">
							Video
						</div>

						<!-- tipo non supportato -->
						<div v-else>
							Nessun risultato
						</div>
					</template>
				</ion-radio-group>


			</template>
			<template v-else>

				<!-- multiple chckbox -->
				<template v-for="option in question.options" :key="option.id">
					
					<!-- html option -->
					<ion-item
						mode="ios"
						lines="none"
						v-if="option.question === 'html'"
					>
						<ion-label>{{ option.value }}</ion-label>
						<ion-checkbox
							slot="start"
							:value="option.id"
							:model="answer.value"
							@ionChange="handleChange"
						></ion-checkbox>
					</ion-item>

					<!-- video option -->
					<div v-else-if="option.question === 'video'">
						Video
					</div>

					<!-- tipo non supportato -->
					<div v-else>
						Nessun risultato
					</div>
				</template>

			</template>
		</ion-list>
	</template>
	<!-- Single -->
	<template v-else>
		<ion-radio-group mode="md" @ionChange="handleChange">
			<ion-grid class="question-option__container">
					<template v-for="option in question.options" :key="option.id">
						<!-- html option -->
						<ion-row v-if="option.question === 'html'">
							<ion-col>
								<ion-card class="card--radio">
									<ion-card-content>
										<div class="d-flex align-items-center">
											<ion-radio
												:value="option.id"
												:model="answer.value"
												slot="start"
											></ion-radio>
											<ion-label>{{ option.value }}</ion-label>
										</div>
									</ion-card-content>
								</ion-card>
							</ion-col>
						</ion-row>

						<!-- video option -->
						<VideoListItem 
							v-else-if="option.question === 'video'" 
							:id="option.id"
							:selected="answer.values"
							:title="option.value" 
							:url="option.url || ''" 
							:buttonLabel="'Seleziona'" 
							@onOpenVideo="showVideoPlayer"
							@onVoteItem="onVideoSelection" 
							/>

						<!-- tipo non supportato -->
						<div v-else>
							Nessun risultato
						</div>
					</template>
			</ion-grid>
		</ion-radio-group>
	</template>

	<div class="ion-text-center button-submitvote">
		<ion-button @click="submitAnswers()" shape="round" class="mt-20" size="large"
			>Conferma e procedi <ion-icon :icon="icons.arrowForwardOutline" slot="end"></ion-icon> </ion-button
		>
	</div>
</template>


<script lang="ts">
import { Question } from "@/models/types";
import { RootStore } from "@/store";
import {
	IonGrid,
	IonRow,
	IonCol,
	IonList,
	IonRadioGroup,
	IonCard,
	IonCardContent,
	IonItem,
	IonRadio,
	IonLabel,
	IonCheckbox,
	IonButton,
	IonIcon,
	alertController
} from "@ionic/vue";
import { computed, defineComponent, PropType, ref, toRefs } from "vue";
import { useStore } from "vuex";
import VideoListItem from '@/components/modals/VideoListItem.vue';
import { arrowForwardOutline } from "ionicons/icons";

const questionModalProps = {
	question: {
		type: Object as PropType<Question>,
		required: true,
		default: {}
	}
}
export default defineComponent({
	components: {
		IonGrid,
		IonRow,
		IonCol,
		IonList,
		IonRadioGroup,
		IonCard,
		IonCardContent,
		IonItem,
		IonRadio,
		IonLabel,
		IonCheckbox,
		IonButton,
		IonIcon,
		VideoListItem
	},
	name: "QuestionModal",
	props: questionModalProps,
	created() {
		const index = this.currentVote.findIndex(
			(vote: any) => vote.id === this.answer.id
		);
		if (index !== -1) this.answer.values = this.currentVote[index].value;
	},
	methods: {
		async showAlert(min: number, max?: number) {
			let message = ""
			if(min && max) {
				message = "Questa domana prevede che tu scelga tra " + min + " e " + max + " risposte. Proseguendo ugualmente la tua scheda potrebbe essere annullata.";
			} else {
				message = 'Questa domanda prevede che tu scelga almeno ' + min + ((min === 1)? " risposta": " risposte") + ". Proseguendo ugualmente la tua scheda potrebbe essere annullata.";
			}
			

			const alert = await alertController
				.create({
				cssClass: 'my-custom-class',
				header: 'Attenzione',
				subHeader: '',
				message: message ,
				buttons: [
					{
						text: 'Annulla',
						role: 'cancel',
						cssClass: 'secondary',
					},
					{
						text: 'Conferma',
						role: 'confirm',
						handler: () => {
							this.confirmVote();
						},
					},
				],
				});
				await alert.present();

				const { role } = await alert.onDidDismiss();
				//console.log('onDidDismiss resolved with role', role);
		},

		async handleChange(eventData: any) {
			const selected = eventData.detail.value;
			this.handleChoice(selected)
		},

		submitAnswers() {
			// check if the answer is correct otherwise alert user for confirm
			if (this.question) {

				// Singola o Radio
				if((!this.isMultiple() || this.isMultipleRadioValue()) && this.answer.values.length === 0) {
					this.showAlert(this.question.rules.min);
				} else if(this.isMultiple() && (this.answer.values.length < this.question.rules.min || this.answer.values.length > this.question.rules.max)) {
					this.showAlert(this.question.rules.min, this.question.rules.max);
				} else {
					this.confirmVote();
				}

			}
		},

		confirmVote() {
			this.$store.dispatch("setCurrentVote", this.answer);
			this.$emit("modal-submit");
		}
	},
	setup(props) {
		const store = useStore<RootStore>()
		const { question } = toRefs(props)

		const answer = ref({
			id: props.question.id,
			values: [] as any[],
		})


		const isMultiple = () => {
			return !(
				question.value.rules.min === 1 && question.value.rules.max == 1
			)
		}

		const isMultipleRadioValue = () => {
			return (
				question.value.rules.min === 1 && question.value.rules.max > 1
			)
		}

		const showVideoPlayer = (value: {url: string; title: string}) => {
			store.dispatch("showVideoPlayer", value)
		}

		const handleChoice = (selected: any) => {
			if (!isMultiple() || isMultipleRadioValue()) {
				answer.value = {...answer.value, values: [selected]}
			} else {
				const index = answer.value.values.indexOf(selected);
				if (index !== -1) {
					answer.value.values.splice(index, 1);
					answer.value = {...answer.value};
				} else {
					answer.value = {...answer.value, values: [...answer.value.values, selected]};
				}
			}
		}

		const onVideoSelection = (value: string) => {
			handleChoice(value)
		}


		return {
			question,	
			answer,
			isMultiple,
			isMultipleRadioValue,
			currentVote: computed(() => store.getters.currentVote),
			showVideoPlayer,
			handleChoice,
			onVideoSelection,
			icons: { 
                arrowForwardOutline
            }
		};
	},
	emits: ["modal-submit"],
});
</script>

<style scoped>
ion-list-header {margin-bottom: 10px}
.question-option__container{margin-bottom: 85px}
</style>
