<template>
	<ion-page>
		<ion-content :fullscreen="true">
			<PageCustomHeader :anonymous="currentBallot.anonymous" :displayLine="true" @onAnonymousClick="openModal('anonymous')" />

			<div class="title-section ion-padding d-flex ion-justify-content-between ion-align-items-center">
				<h4>
					<ion-text color="primary">
						{{ currentBallot.title.it }}
					</ion-text>
				</h4>
			<div>
        <ion-icon class="fs-30 ion-margin-end" color="primary" v-if="currentBallot.anonymous" @click="openModal('anonymous', 'Informazioni')" :icon="icons.eyeOff" slot="start">
        </ion-icon>
        <ion-icon class="fs-30 ion-margin-end" color="primary" @click="openModal('ballotInformation', 'Informazioni', currentBallot)" :icon="icons.shieldCheckmark" slot="start">
        </ion-icon>
        <ion-icon class="fs-30 mr-0" color="primary" @click="openModal('ballotTerms', 'Termini e condizioni votazione')" :icon="icons.informationCircle" slot="start">
        </ion-icon>

			</div>
		</div>

		<div class="ion-margin mt-0">
			<ion-item lines="none" class="ion-no-padding ion-no-margin mh-20">
			<ion-label slot="start" class="mb--10">
				<h4><ion-text class="color-gray"><b>INIZIO VOTAZIONE</b></ion-text></h4>
				<h2><b>{{$filters.formatDate(currentBallot.start)}}</b></h2>
			</ion-label>

			<ion-label slot="end" class="mr--10 oo-h ion-text-right mb--3">
				<h4><ion-text class="color-gray"><b>FINE VOTAZIONE</b></ion-text></h4>
				<h2><b>{{$filters.formatDate(currentBallot.end)}}</b></h2>
			</ion-label>

			</ion-item>

				<div class="lineatemp custom">
					<ProgressBarComponent
						:start="currentBallot.start"
						:end="currentBallot.end"
					></ProgressBarComponent>
				</div>
			</div>

			<ion-grid class="grid-cards ion-no-padding">
				<ion-row class="grid-cards__row ion-no-padding">
					<ion-col class="ion-no-padding">
						<ion-slides
							pager="true"
							:options="slideOpts"
							class="custom"
							ref="sliderRef"
						>
							<ion-slide
								v-for="(question, index) in currentBallot
									.questions"
								:key="question.id"
							>
								<div class="stepper-card">
									<SlideMenuComponent
										:questions="currentBallot.questions"
										:index="index"
									></SlideMenuComponent>
								</div>
								<ion-card class="swipe-card">
									<!--ribbon for question compèleted-->
									<template
										v-if="questionHasResponse(question.id)"
									>
										<div class="triangle-topright"></div>
										<ion-icon
											class="triangle-topright__icon"
											:icon="icons.checkmarkCircle"
										></ion-icon>
									</template>
									<!--ribbon for question compèleted-->

									<ion-card-content class="swipe-card__content">
										<div style="height: 100%;display: flex;align-items: center;">
											<div style="width: 100%">
												<div class="img-urna">
													<div class="img-urna__mask"></div>
												</div>
												<h1>
													<b>{{question.question}}</b>
												</h1>
											</div>
										</div>
										<ion-button
											@click="openModal(MODALTYPE.question, question.question, question, infoModal)"
											v-if="!questionHasResponse(question.id)"
											expand="block"
											class="mt-auto"
											size="large"
											shape="round"
											>
											Vai alle opzioni
										</ion-button>
									</ion-card-content>
								</ion-card>
							</ion-slide>
						</ion-slides>
					</ion-col>
				</ion-row>
			</ion-grid>

			<ion-modal
				:is-open="modalInfo.show"
				css-class="custom"
				@didDismiss="handleModalClosed"
			>
				<Modal
					:type="modalInfo.type"
					:title="modalInfo.title"
					:data="modalInfo.data"
					:info="modalInfo.info"
					@modal-closed="handleModalClosed"
					@modal-submit="submitModal"
				></Modal>
			</ion-modal>
		</ion-content>
	</ion-page>
</template>

<script lang="ts">
import {
	IonContent,
	IonPage,
	IonRow,
	IonCol,
	IonSlides,
	IonSlide,
	IonGrid,
	IonCard,
	IonCardContent,
	IonItem,
	IonButton,
	IonIcon,
	IonModal,
} from "@ionic/vue";
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { informationCircleOutline, ellipse, ellipseOutline, checkmarkCircle, shieldCheckmark, eyeOff, informationCircle } from "ionicons/icons";
import Modal from "@/components/modals/index.vue";
import SlideMenuComponent from "@/components/SlideMenuComponent.vue";
import ProgressBarComponent from "@/components/layout/ProgressBarComponent.vue";
import PageCustomHeader from '@/components/PageCustomHeader.vue'
import useModal from "@/composables/ModalApi";
import { useRouter } from "vue-router";
import { Ballot } from "@/models/types";

export default defineComponent({
	name: "question-list",
	components: {
		IonContent,
		IonPage,
		IonSlides,
		IonSlide,
		IonRow,
		IonCol,
		IonGrid,
		IonCard,
		IonCardContent,
		IonItem,
		IonButton,
		IonIcon,
		Modal,
		IonModal,
		SlideMenuComponent,
		ProgressBarComponent,
		PageCustomHeader
	},
	props: {},
	data() {
		return {
			MODALTYPE: {
				anonymous: "anonymous",
				ballotInformation: "ballotInformation",
				question: "question",
				language: "language",
			},
		};
	},
	methods: {
		// Check if current question has response
		questionHasResponse(id: string) {
			return !!this.currentVote.find((answer: any) => {
				return answer.id === id;
			});
		}
	},

	setup() {
		const store = useStore()
		const {scopedModalInfo, openModal, handleModalClosed, handleSubmitModal} = useModal("scoped")
		const router = useRouter()
		const currentBallot = computed<Ballot>(() => store.getters.currentBallot )
		const currentVote = computed(() => store.getters.currentVote)
		const sliderRef = ref()

		// Check if all question has response, can be empty answers.
		const questionHasFullResponse = () => {
			const questionsCount = currentBallot.value.questions.length;
			return questionsCount === currentVote.value.length;
		}

		const questionAction = () => {
			const slider: any = sliderRef.value;
			if (questionHasFullResponse()) {
				//if (await slider.$el.isEnd()) {
				// Navigate to resume
				router.replace({ name: "vote-confirm" });
			} else {
				slider.$el.slideNext();
			}
		}

		const submitModal = () => {
			const callback = () => {
				const type = scopedModalInfo.type;

				switch (type) {
					case "language":
						//console.log("Language selected -> ", eventData.data);
						// Change language
						break;
					case "question":
						// Mi salvo il voto nel vettore
						questionAction();
						break;
				}
			}
			handleSubmitModal(callback)
		}

		// Optional parameters to pass to the swiper instance. See http://idangero.us/swiper/api/ for valid options.
		const slideOpts = {
			slidesPerView: 1.3,
			initialSlide: 0,
			speed: 400,
			centeredSlides: true,
			pagination: false,
		};

		const infoModal = "I dati inseriti in questa scheda non saranno oggetto di alcun trattamento, non saranno conservati da E-Vote.io e saranno utilizzati soltanto per l’invio della votazione"

		return {
			icons: { 
				informationCircleOutline, 
				ellipse, 
				ellipseOutline, 
				checkmarkCircle, 
				shieldCheckmark, 
				eyeOff,
				informationCircle
			},
			infoModal,
			slideOpts,
			modalInfo: scopedModalInfo,
			openModal,
			handleModalClosed,
			currentBallot,
			currentVote,
			submitModal,
			questionAction,
			questionHasFullResponse,
			sliderRef
		};
	},
});
</script>

<style scoped>
.head.custom {
  margin-bottom: 10px;
}
.title-section .page-title,
.title-section {
	margin: 0px;
	padding: 0px;
}
.title-section {
  padding-bottom: 10px;
  margin: 0 30px;
}
@media (max-width: 575.98px) {
  .title-section {
    padding-bottom: 0px;
    margin: 0 15px;
  }
}

</style>
