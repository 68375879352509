<template ref="template">
	<div class="ion-padding-horizontal">
		<ion-text color="dark">
			<h3 class="mt-0">Certificazione della regolarità della votazione</h3>
		</ion-text>
	</div>

	<ion-card>
		<ion-card-content>
			<ion-list class="mb-20">
				<ion-item lines="none" style="margin-left: -15px">
					<ion-thumbnail slot="start">
						<img onerror="this.src='/assets/icon/logo-evote.png'" src="/assets/logo-customer.png" />
					</ion-thumbnail>
					<ion-label>
            <h2>Titolo votazione:</h2>
            <h3>{{ ballot.title.it }}</h3>
          </ion-label>
				</ion-item>
			</ion-list>

			<div class="well mt-5 mb-10">
				{{ hash }}
			</div>
			<ion-button v-on:click="checkHash" shape="round" color="primary" expand="block"
				>Controllo HASH votazione</ion-button
			>
			<template v-if="hashChecked">
				<div v-if="hashValid" class="d-flex justify-content-center padding-top-m">
					<div class="margin-top-s">
						<img class="text-center--img_text" src="/assets/check-circle-fill.png" />
						<p style="color: green"><b>Hash valido!</b></p>
					</div>
				</div>
				<div v-if="!hashValid" class="d-flex justify-content-center padding-top-m">
					<div class="margin-top-s">
						<img class="text-center--img_text" src="/assets/close-circle-sharp.png" />
						<p style="color: darkred"><b>Hash non valido!</b></p>
					</div>
				</div>
			</template>
		</ion-card-content>
	</ion-card>

	<ion-card>
		<ion-card-content>
			<ion-text color="dark">
				<h5><b>Cosa succede in caso di HASH non valido?</b></h5>
			</ion-text>

			<p>
				Potrebbe essersi verificata una manomissione dei dati della
				votazione.
			</p>
		</ion-card-content>
	</ion-card>

	<div class="ion-padding">
		<ion-text color="dark">
			<h5><b>Descrizione completa della votazione:</b></h5>
		</ion-text>
		<p>{{ ballot.description.it }}</p>
	</div>

	<!-- In attesa dei links definitivi
	<ion-row class="ion-margin text-little">
		<ion-col size="6">
			<ion-button expand="full" fill="outline" shape="round"
				>Privacy policy</ion-button
			>
		</ion-col>
		<ion-col size="6">
			<ion-button expand="full" fill="outline" shape="round"
				>Termini e condizioni</ion-button
			>
		</ion-col>
	</ion-row>
	-->
</template>


<script lang="ts">
import { Ballot } from "@/models/types";
import { Subtle, Utils } from "@affidaty/t2-lib";
import {
/* 	IonRow, 
	IonCol,  */
	IonButton,
	IonText,
	IonThumbnail,
	IonLabel,
	IonList,
	IonCardContent,
	IonCard,
	IonItem
} from "@ionic/vue";
import { defineComponent, PropType } from "vue";

export default defineComponent({
	components: {
/* 		IonRow,
		IonCol, */
		IonButton,
		IonText,
		IonThumbnail,
		IonLabel,
		IonList,
		IonCardContent,
		IonCard,
		IonItem
	},
	name: "BallotInformationModal",
	data() {
		return {
			hashChecked: false,
			hashValid: false,
			hash: "",
		};
	},
	props: {
		ballot: {
			type: Object as PropType<Ballot>,
			required: true,
		},
	},

	mounted() {
		this.hash = this.ballot.hash;
	},

	methods: {
		checkHash() {
			// Clone ballot object 
			const {isVoted, hash, signature, status, results, publicBallot, logo, icon,  ...filteredBallot} = {...this.ballot};
      const data = {
				title: this.ballot.title,
				description: this.ballot.description,
				start: this.ballot.start,
				end: this.ballot.end,
				anonymous: this.ballot.anonymous,
				rules: this.ballot.rules,
				questions: this.ballot.questions,
				'polling_stations': this.ballot['polling_stations'],
				applicationKey: this.ballot.applicationKey,
				owner: this.ballot.owner
			}
			// hash the message
			Subtle.digest('SHA-256', Utils.objectToBytes(data)).then((hashBuffer: ArrayBuffer) => {
				// convert buffer to byte array
				const hashArray = Array.from(new Uint8Array(hashBuffer));            
				// convert bytes to hex string         
				const ballotHashString = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
				this.hashChecked = true;
				this.hashValid = hash === ballotHashString;	
			})         

			// ObjectTransporter.hash(ballot).then((ballotHash: any) => {
			// 	this.hashChecked = true;
			// 	this.hashValid = this.hash === ballotHash;
			// });
		},
	},
});
</script>

<style scoped>
p.text-small {
	font-size: 0.66em;
}

.well {
	background: #f5f5f5;
	border-radius: 20px;
	padding: 20px;
}
</style>
