<template ref="template">
	<ion-row class="d-flex justify-content-center">
		<img onerror="this.src='/assets/icon/logo.png'" src="/assets/logo.svg" class="logo" />
	</ion-row>
	<!-- <p class="ion-text-center">Vot8</p> -->
	<div class="title-section">
		<h2 class="ion-text-center page-title">Voto anonimo</h2>
	</div>
	<p class="ion-margin">
		Questa votazione utilizza il sistema di VOTO ANOMINO garantito da
		Affidaty S.p.A e basato sulla tecnologia Blockchain.
	</p>
	<!--c<ion-row class="ion-margin ion-padding ion-text-center text-little">
		<ion-col size="6"><a>Privacy policy</a></ion-col>
		<ion-col size="6"><a>Termini e condizioni</a></ion-col>
	</ion-row> -->
	<ion-row >
		<ion-col>
			<div class="powered ion-margin">
				Powered by <img src="assets/affidaty.png" class="logo-footer" />
			</div>
		</ion-col>
	</ion-row>
</template>


<script lang="ts">
import { IonRow, IonCol } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
	components: {
		IonRow,
		IonCol,
	},
	name: "AnonymousModal",
	data() {
		return {};
	},
	props: {},
});
</script>

<style scoped>
</style>
