
import {
	IonContent,
	IonPage,
	IonRow,
	IonCol,
	IonSlides,
	IonSlide,
	IonGrid,
	IonCard,
	IonCardContent,
	IonItem,
	IonButton,
	IonIcon,
	IonModal,
} from "@ionic/vue";
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { informationCircleOutline, ellipse, ellipseOutline, checkmarkCircle, shieldCheckmark, eyeOff, informationCircle } from "ionicons/icons";
import Modal from "@/components/modals/index.vue";
import SlideMenuComponent from "@/components/SlideMenuComponent.vue";
import ProgressBarComponent from "@/components/layout/ProgressBarComponent.vue";
import PageCustomHeader from '@/components/PageCustomHeader.vue'
import useModal from "@/composables/ModalApi";
import { useRouter } from "vue-router";
import { Ballot } from "@/models/types";

export default defineComponent({
	name: "question-list",
	components: {
		IonContent,
		IonPage,
		IonSlides,
		IonSlide,
		IonRow,
		IonCol,
		IonGrid,
		IonCard,
		IonCardContent,
		IonItem,
		IonButton,
		IonIcon,
		Modal,
		IonModal,
		SlideMenuComponent,
		ProgressBarComponent,
		PageCustomHeader
	},
	props: {},
	data() {
		return {
			MODALTYPE: {
				anonymous: "anonymous",
				ballotInformation: "ballotInformation",
				question: "question",
				language: "language",
			},
		};
	},
	methods: {
		// Check if current question has response
		questionHasResponse(id: string) {
			return !!this.currentVote.find((answer: any) => {
				return answer.id === id;
			});
		}
	},

	setup() {
		const store = useStore()
		const {scopedModalInfo, openModal, handleModalClosed, handleSubmitModal} = useModal("scoped")
		const router = useRouter()
		const currentBallot = computed<Ballot>(() => store.getters.currentBallot )
		const currentVote = computed(() => store.getters.currentVote)
		const sliderRef = ref()

		// Check if all question has response, can be empty answers.
		const questionHasFullResponse = () => {
			const questionsCount = currentBallot.value.questions.length;
			return questionsCount === currentVote.value.length;
		}

		const questionAction = () => {
			const slider: any = sliderRef.value;
			if (questionHasFullResponse()) {
				//if (await slider.$el.isEnd()) {
				// Navigate to resume
				router.replace({ name: "vote-confirm" });
			} else {
				slider.$el.slideNext();
			}
		}

		const submitModal = () => {
			const callback = () => {
				const type = scopedModalInfo.type;

				switch (type) {
					case "language":
						//console.log("Language selected -> ", eventData.data);
						// Change language
						break;
					case "question":
						// Mi salvo il voto nel vettore
						questionAction();
						break;
				}
			}
			handleSubmitModal(callback)
		}

		// Optional parameters to pass to the swiper instance. See http://idangero.us/swiper/api/ for valid options.
		const slideOpts = {
			slidesPerView: 1.3,
			initialSlide: 0,
			speed: 400,
			centeredSlides: true,
			pagination: false,
		};

		const infoModal = "I dati inseriti in questa scheda non saranno oggetto di alcun trattamento, non saranno conservati da E-Vote.io e saranno utilizzati soltanto per l’invio della votazione"

		return {
			icons: { 
				informationCircleOutline, 
				ellipse, 
				ellipseOutline, 
				checkmarkCircle, 
				shieldCheckmark, 
				eyeOff,
				informationCircle
			},
			infoModal,
			slideOpts,
			modalInfo: scopedModalInfo,
			openModal,
			handleModalClosed,
			currentBallot,
			currentVote,
			submitModal,
			questionAction,
			questionHasFullResponse,
			sliderRef
		};
	},
});
