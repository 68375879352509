<template>
    <ion-header class="ion-no-border">
        <div class="head">
            <div class="line-sx"></div>
            <img onerror="this.src='/assets/icon/logo.png'" src="/assets/logo.svg" class="logo" />
            <div class="line-dx"></div>
        </div>
    </ion-header>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import {
	IonHeader,
} from "@ionic/vue";

export default defineComponent({
    components: {
        IonHeader
    }
})
</script>
