<template>
	<ion-footer class="ion-padding">
		<ion-row class="menu-voteadm">
			<ion-col size="4">
				<router-link to="/dashboard">
					<ion-icon :icon="icons.homeOutline"></ion-icon>
				</router-link>
				<ion-label>Votazioni</ion-label>
			</ion-col>
<!--	<ion-col>
				<router-link to="/dashboard">
					<ion-icon :icon="icons.fileTrayFullOutline"></ion-icon>
				</router-link>
				<ion-label>Votazione</ion-label>
			</ion-col>-->
			<ion-col size="4">
				<router-link to="/guide">
					<ion-icon :icon="icons.informationCircleOutline"></ion-icon>
					<ion-label>Info</ion-label>
				</router-link>
			</ion-col>
			<ion-col size="4">
				<router-link to="/logout">
					<ion-icon :icon="icons.exitOutline"></ion-icon>
					<ion-label>Logout</ion-label>
				</router-link>
			</ion-col>
		</ion-row>
	</ion-footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { homeOutline, informationCircleOutline, personOutline, exitOutline } from "ionicons/icons";
import { 
    IonFooter,
    IonIcon, 
    IonRow,
    IonCol ,
    IonLabel
} from "@ionic/vue";

export default defineComponent({
	name: "Footer",
    components: {
        IonFooter,
        IonIcon, 
        IonRow,
        IonCol ,
        IonLabel
    },
    setup() {
		return {
			icons: { 
				homeOutline,
				informationCircleOutline,
				personOutline,
        exitOutline
			},
		};
	},
});
</script>
