<template>
	<ion-app>
		<ion-router-outlet animated="false" />
		<AuthInComponent
			v-if="user && user.userInfo"
			ref="authIn"
			:lang="'it-IT'"
			:env="env"
			:apiendpoint="authinApiendpoint"
			:apikey="authinApikey"
			:application="authinApplication"
			@onAuthInReady="authReady"/>
		<YouTubeVideoPlayer 
			v-if="showVideoPlayer && showVideoPlayer.url" 
			:url="showVideoPlayer.url" 
			:autoPlay="true" 
			:videoTitle="showVideoPlayer.title"
			@onCloseVideo="hideVideo"/>
	</ion-app>
</template>

<script lang="ts">
import { IonApp, IonRouterOutlet, IonModal } from "@ionic/vue";
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { RootStore } from "./store";
import AuthInComponent from '@/components/AuthInComponent.vue'
import YouTubeVideoPlayer from '@/components/YouTubeVideoPlayer.vue';

export default defineComponent({
	name: "App",
	components: {
		IonApp,
		IonRouterOutlet,
		AuthInComponent,
		YouTubeVideoPlayer
	},
    setup() {
        const store = useStore<RootStore>()
		// video player vars
		const showVideoPlayer = computed<{url: string; title: string}>(() => store.getters.showVideoPlayer)
		const authIn = ref();
		const authReady = (target: any) => {
			store.commit("setAuthInRef", authIn.value? authIn.value : target)
		}

		const hideVideo = () => {
			store.commit("hideVideoPlayer")
		}
        return {
			authinApplication: process.env.VUE_APP_AUTHIN_APPLICATION,
			authinApiendpoint: process.env.VUE_APP_AUTHIN_APIENDPOINT,
			authinApikey: process.env.VUE_APP_AUTHIN_APIKEY,
			env: process.env.VUE_APP_ENVIRONMENT,
			authReady,
			authIn,
            user: computed(() => store.getters.userInformations),
			showVideoPlayer,
			hideVideo
        }
    }
});
</script>