
import { defineComponent, toRefs } from 'vue';
import {
	IonLabel,
    IonItem
} from "@ionic/vue";
 
const listItemImageProps = {
    id: {
        type: String,
        required: true
    },
    title: {
        type: String,
        required: true,
        default: "",
    },
    subtitle: {
        type: String,
        required: true,
        default: ""
    },
    url: {
        type: String,
        required: true,
        default: "",
    },
    status: {
        type: String,
        required: true,
        default: ""
    }
}
export default defineComponent({
    components: {
        IonLabel,
        IonItem
    },
    emits: ["onClickItem"],
    props: listItemImageProps,
    setup(props, {emit}) {
        const {
            title, 
            url,
            subtitle,
            id,
            status
        } = toRefs(props)

        const onClickItem = () => {
           emit("onClickItem", {url: url.value, title: title.value})
        }

        return {
            onClickItem,
            title, 
            url,
            subtitle,
            id,
            status
        }
    },
})
