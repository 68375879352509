
import { IonRow, IonCol } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
	components: {
		IonRow,
		IonCol,
	},
	name: "AnonymousModal",
	data() {
		return {};
	},
	props: {},
});
