
import {
  IonContent,
  IonPage,
  IonIcon,
  IonAvatar,
  IonCol,
  IonRow,
  IonButton,
  IonGrid,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { checkmarkCircle, homeOutline, fileTrayFullOutline } from "ionicons/icons";
import Footer from "@/components/layout/Footer.vue";
import PageCustomHeader from '@/components/PageCustomHeader.vue'

export default defineComponent({
  name: "vote-thanks",
  components: {
    IonContent,
    IonPage,
    IonIcon,
    IonAvatar,
    IonCol,
    IonRow,
    IonButton,
    IonGrid,
    Footer,
    PageCustomHeader
  },
  methods: {},
  setup() {
    return {
      icons: { checkmarkCircle, homeOutline, fileTrayFullOutline }
    };
  },
});
