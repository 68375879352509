import { binary_to_base58 as binaryToBase58, base58_to_binary as base58ToBinary } from "base58-js";
import * as buffer from 'buffer';
import { toBigIntBE } from "bigint-buffer";
import { binConversions, Subtle } from "@affidaty/t2-lib";
import moment from "moment";

export function arrayBufferToBase58(buffer: any) {
    const bytes = new Uint8Array(buffer);
    return binaryToBase58(bytes);
}

/**
 * Base58 to ArrayBuffer transformation
 *
 * @param {String} base58
 * @returns {ArrayBuffer} the ArrayBuffer string encoding
 */
export function base58ToArrayBuffer(base58: any) {
    return base58ToBinary(base58).buffer;
}

/**
 * From ArrayBuffer to Buffer
 *
 * @param arrayBuffer
 * @return {Buffer}
 */
export function arrayBufferToBuffer(arrayBuffer: any) {
    return buffer.Buffer.from(arrayBuffer);
}

/**
 * From Buffer to ArrayBuffer
 *
 * @param buffer buffer to convert
 * @return {arrayBuffer}
 */
export function bufferToArrayBuffer(buffer: any) {
    const arrayBuffer = new ArrayBuffer(buffer.length);
    const arrayBufferView = new Uint8Array(arrayBuffer);
    for (let i = 0; i < buffer.length; ++i) {
        arrayBufferView[i] = buffer[i];
    }
    return arrayBuffer;
}


/**
 * Base58 to Buffer transformation
 *
 * @param {String} base58
 * @returns {Buffer} the Buffer string encoding
 */
export function base58ToBuffer(base58: any) {
    const buffer = base58ToBinary(base58).buffer;
    return arrayBufferToBuffer(buffer);
}

/**
     * ArrayBuffer to base58 transformation
     *
     * @param {ArrayBuffer} buffer
     * @returns {String} the base58 buffer encoding
     */
export function bufferToBase58(buffer: any) {
    const bytes = new Uint8Array(buffer);
    return binaryToBase58(bytes);
}

/**
* Computes polling station index from arbitrary data
* @param idV idV of random wallet generated (account id)
* @param modulo total number of indexes
* @param saltBuffer
*/
export function indexFromData(idV = Buffer.from([0]), modulo = 10, salt: any = null) {
    return new Promise((resolve, reject) => {
        let buff = Buffer.from(idV);
        if (salt) {
            buff = Buffer.concat([buff, Buffer.from(salt)]);
        }
        Subtle.digest("SHA-384", buff).then((digest: any) => { //Crypto.createHash('sha384').update(buff).digest();
            digest = binConversions.arrayBufferToBuffer(digest);
            const idx = Number(toBigIntBE(digest) % BigInt(modulo));
            resolve(idx);
        }).catch((err: any) => { reject(err) });
    })
}

/**
* Converts buffer to a base64url string
* @param buffer{Buffer} input buffer
* @returns {String} base64url string
*/
export function bufferToBase64url(buffer: any) {
    return buffer.toString("base64").replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}

/**
* Converts buffer to a base64url string
* @param b64urlString{String} Base64url string to convert
* @returns {Buffer} buffer
*/
export function base64urlToBuffer(b64urlString: string) {
    return Buffer.from(b64urlString.replace(/-/g, '+').replace(/_/g, '/'), "base64");
}

/**
* Given an array of ArrayBuffer objects, this function concatenates them into a single ArrayBuffer
* @param arrayOfArrayBuffers{Array<ArrayBuffer>} array of ArrayBuffers
* @returns {ArrayBuffer} resulting ArrayBuffer
*/
export function concatArrayBuffers(arrayOfArrayBuffers: any) {
    let totalByteLength = 0;
    const offsets = [0];
    for (let i = 0; i < arrayOfArrayBuffers.length; i++) {
        totalByteLength += arrayOfArrayBuffers[i].byteLength;
        offsets.push(totalByteLength);
    }
    offsets.pop();
    const result = new ArrayBuffer(totalByteLength);
    const resultView = new Uint8Array(result);
    for (let i = 0; i < arrayOfArrayBuffers.length; i++) {
        resultView.set(new Uint8Array(arrayOfArrayBuffers[i]), offsets[i]);
    }
    return result;
}


/**
 * Check if object is empty or false (null, undefined...)
 * @param object generic Object 
 * @returns boolean
 */
export const isEmptyOrNull = (object: any): boolean => {
    return !object 
    || Object.keys(object).length === 0
}

// Format date 
export const formatDate = (value: number, seconds = false) => {
    return (seconds) ? moment(value * 1000).format('DD/MM/YYYY hh:mm') : moment(value * 1000).format('DD/MM/YYYY')
}

export const getMomentDateFromInt = (value: number) => {
    return moment(value * 1000)
}